let translateADS = {
    'en':
    {
        addBTN: 'Add',
        labelFilterDate: 'Filter by Start Date and End Date',
        buttonSelectDate: 'Select Start Date & End Date',
        textFilterInputs: 'Filter by City || Ads Location || Ads Service',
        startDate: 'Start Date',
        endDate: 'End Date',
        labelCountry: 'Country',
        optionCountry: 'choose a country',
        labelCity: 'City',
        labelArea: 'Area',
        optionCity: 'Choose a city',
        optionArea: 'Choose a area',
        labelAdvertisementLocation: 'Location',
        optionAdvertisementLocation: 'choose a location',
        labelAdvertisementService: 'Service',
        optionAdvertisementService: 'choose a service',
        optionCate: 'choose a category',

        TableHeader: [
            "Image",
            "Service",
            "Start-Date",
            "End-Date",
            "Location",
            "Action",
        ],
        ActionsLabel: 'Actions',
        headerModalDel: 'Delete Advertisement',
        btnModalDel: 'Delete Now',
        labelImageInput: 'Ads Image',
        labelCountryInput: 'Country',
        labelCityInput: 'City',
        labelAdsServiceInput: 'Ads Service',
        labelAdsLocationInput: 'Ads Location',
        labelStartInput: 'Start Date',
        labelEndInput: 'End Date',
        labelLinkInput: '  Link',
        optionAdvertisementLink: 'choose a link',
        LabelAddPage: 'Add new Ads',
        SaveBTN: 'Save',
        CancelBTN: 'Cancel',
        LabelEditPage: 'Edit the Ads',
        AnimalCategories:'Animal Categories',
        filter: {
            Country: 'Select Country',
            allCountry: 'Countries',
            city: 'Select Area',
            allCity: 'Areas',
            area: 'Select City',
            allarea: 'Cities',
            status: 'Select Status',
            allStatus: 'All Status',
            Product: 'Product Type',
            Products: 'Animals Product Type',
            SubCategory: 'Select SubCategory',
            allSubCategory: 'Animals SubCategory'
        },
        adsLocation: [
            { value: 'HOME', text: 'Home' },
            // { value: 'PAGES', text: 'Pages' },
            { value: 'INNER_PAGES', text: 'Inner Pages' },
        ],
        adsService: [
            { value: 'NONE', text: 'None' },
            { value: 'URGENT_CONSULT', text: 'Urgent consult' },
            { value: 'CONSULT', text: 'Normal Consult  ' },
            // { value: 'CLIENT_BLOG', text: 'Client blog' },
            { value: 'DOCTOR_BLOG', text: 'Doctor blog' },
            { value: 'ADOPTION', text: 'Adoption' },
            { value: 'BIDDING', text: 'Bidding' },
            { value: 'SALE', text: 'Sale' },
        ],
    },
    'ar':
    {
        addBTN: 'إضافـة',
        labelFilterDate: 'البحث بواسطـة تاريـخ البـدء و تاريخ الإنتهاء',
        buttonSelectDate: 'تحديـد تاريـخ البـدء وتاريخ الإنتهـاء',
        textFilterInputs: 'البحث بواسطة المدينـة || موقـع الإعـلان || خدمـة الإعـلان',
        startDate: 'تاريـخ البدء',
        endDate: 'تاريـخ الإنتهاء',
        labelCountry: 'البلد',
        optionCountry: 'اختر البلد',
        labelCity: 'المدينـة',
        optionCity: 'اختر المدينـة',
        labelAdvertisementLocation: 'موقـع الإعـلان',
        optionAdvertisementLocation: 'اختـر الموقـع',
        labelAdvertisementService: 'خدمـة الإعـلان',
        optionAdvertisementService: 'اختـر الخدمـة',
        optionCate: 'إخــر فئة من الحيوانات',
        AnimalCategories:'فئات الحيوانات',
        TableHeader: [
            "الصـورة",
            "الخدمـة",
            "تاريـخ البدء",
            "تاريـخ الإنتهـاء",
            "الموقـع",
            "الإجـراء",
        ],
        ActionsLabel: 'الإجـراءات',
        headerModalDel: 'حـذف الإعـلان',
        btnModalDel: 'حـذف الآن',
        labelImageInput: 'صـورة الإعـلان',
        labelCountryInput: 'البلـد',
        labelCityInput: 'المدينـة',
        labelArea: 'المنطقة',
        optionArea: 'اختــار المنطقة',
        labelAdsServiceInput: 'خدمـة الإعـلان',
        labelAdsLocationInput: 'موقـع الإعـلان',
        labelStartInput: 'تاريـخ البـدء',
        labelEndInput: 'تاريـخ الإنتهـاء',
        labelLinkInput: 'الرابــط',
        optionAdvertisementLink: 'اختـر الرابـط',
        LabelAddPage: 'إضافـة إعـلان جديـد',
        SaveBTN: 'حفـظ',
        CancelBTN: 'رجـوع',
        LabelEditPage: 'تعديــل الإعـلان',

        filter: {
            Country: 'حدد الدولة',
            allCountry: 'بلدان',
            city: ' حدد منطقة  ',
            allCity: 'المناطق',
            area: 'حدد مدينه',
            allarea: 'مدن',
            status: 'حدد الحالة',
            allStatus: 'كل الحالة',
            Product: 'نوع المنتج',
            Products: '    حدد نوع المنتج    ',
            SubCategory: 'حدد الفئة الفرعية',
            allSubCategory: 'فئة الحيوانات'
        },

        adsLocation: [
            { value: 'HOME', text: 'الصفحة الرئيسية' },
            // { value: 'PAGES', text: 'الصفحات' },
            { value: 'INNER_PAGES', text: 'الصفحات الداخلية' },
        ],
        adsService: [
            { value: 'NONE', text: 'لا شيء' },
            { value: 'URGENT_CONSULT', text: 'استشارة عاجلة' },
            { value: 'CONSULT', text: ' استشارة عادية  ' },
            // { value: 'CLIENT_BLOG', text: 'مدونة العميل' },
            { value: 'DOCTOR_BLOG', text: 'مدونة الطبيب' },
            { value: 'ADOPTION', text: 'تبني' },
            { value: 'BIDDING', text: 'مـزادات' },
            { value: 'SALE', text: 'متجر' },
        ],
    }
}


export default translateADS